import React from 'react'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import { LargeP, DownloadButton, FlexList, FlexItem } from '../elements'

const MaintenanceTechniciansPage = ({ data }) => {
  const postNode = {
    title: `Maintenance Technicians - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Maintenance Technicians - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <ServiceContainer>
        <PageTitle>Maintenance Technicians</PageTitle>
        <LargeP>
          Safety is D&D's number one priority. We believe safety is an attitude
          and a choice—it guides every decision we make.
        </LargeP>
        <p>
          Everything from the equipment we run to the future of this
          organization is backed by safe and conscious decisions. We invest in
          technology to make your job easier and safer. Our maintenance and shop
          personnel are at the core of our safety ethos. We empower and rely
          upon the best technicians to carry the torch and foster our culture of
          safety.
        </p>

        <h4>No Current Open Positions</h4>
        <p>
          We're sorry, there are currently no positions in this category.
          However, you can send us your application and we'll let you know if
          anything comes up.
        </p>

        <DownloadButton
          style={{ marginBottom: '2rem' }}
          rel="noopener noreferrer"
          href={withPrefix('/DDTSI-EmployeeApplication-20141211.pdf')}
          target="_blank"
        >
          Download the Employment Application&nbsp;
          <i className="material-icons">get_app</i>
        </DownloadButton>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default MaintenanceTechniciansPage
